<template>
    <b-row class="match-height">
    <b-col
      md="12"
      lg="12"
    >
      <b-card>
        <b-card-text>
          {{this.$coddyger.buildGreeting()}}
        </b-card-text>
        <hr />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BFormFile, BCard, BAlert, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink } from 'bootstrap-vue'

export default {
    components: {
      BFormFile,
      BCard,
      BCardText,
      BButton,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BRow,
      BCol,
      BLink,
      BImg,
      BAlert,
    },
    setup() {
        
    },
}
</script>
